@tailwind base;
@tailwind components;
@tailwind utilities;


.hero-section {
    background-image: url('../public/mobile-bg.png');
   
  }
  
  @media (min-width: 930px) {
    .hero-section {
      background-image: url('../public/background.png');
    }
  }


  
  .loader {
    /* border: 2px solid red; */
    width: 30px;
    height:30px;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,white 94%,#0000) top/2px 2px no-repeat,
      conic-gradient(#0000 30%,white);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 6px),#000 0);
    animation:s3 1s infinite linear;
  }
  
  @keyframes s3{ 
    100%{transform: rotate(1turn)}
  }

  /* .custom-input{
    width: 100%;
    max-width: 250px;
    padding: 8px;
    text-align: center;
    font-size: 16px;
    color: black;
    background: #BFC2E4;
    outline: none;
    border-radius: 9999px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .custom-input::placeholder {
    color:black
  } */

  @media (max-width: 640px) { /* For small screens */
    .custom-calendar {
      transform: scale(0.85); /* Shrinks the calendar */
      width: 100%; /* Adjust width */
    }
  
    .custom-calendar .rdp { 
      font-size: 14px; /* Smaller font */
    }
  }


  .update{

    transition: all 0.5s ease-in-out;
    text-align: center;
    animation: flicker 0.5s ease-in-out infinite alternate
  }

  @keyframes flicker {
    0% {
      opacity: 0.5;

    }
    100% {
      opacity: 1;
      
    }
  }
  
  